"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function openRedirectModalForDropdown(url) {
    const modalsEls = document.querySelectorAll(".modal[data-modal='uruguay-modal']");
    modalsEls.forEach((modal) => {
        const modalLinkEls = modal.querySelectorAll(".--modal-link");
        const closeModal = modal.querySelector(".modal-close-button");
        const contentWrapper = modal.querySelector(".content-wrapper");
        const imageWrapper = modal.querySelector(".image-wrapper");
        const closeEls = [closeModal];
        modalLinkEls.forEach((btn) => {
            closeEls.push(btn);
            btn.href = url;
            btn.target = "_self";
        });
        closeEls.forEach((el) => {
            el.addEventListener("click", () => {
                modal.setAttribute("closing", "");
                modal.addEventListener("animationend", () => {
                    modal.removeAttribute("closing");
                    modal.close();
                }, { once: true });
            });
        });
        // Agregar listener al backdrop
        modal.addEventListener("click", (event) => {
            if (!contentWrapper.contains(event.target) &&
                !imageWrapper.contains(event.target) &&
                !closeModal.contains(event.target)) {
                modal.setAttribute("closing", "");
                modal.addEventListener("animationend", () => {
                    modal.removeAttribute("closing");
                    modal.close();
                }, { once: true });
            }
        });
        modal.showModal();
    });
}
function initDropdown() {
    const dropdowns = document.querySelectorAll(".dropdown-footer");
    dropdowns.forEach((dd) => {
        // Save the initial value
        const initialValue = dd.value;
        dd.addEventListener("change", (e) => {
            // Get the url from the value
            const url = e.target.value;
            const inputName = dd.name;
            if (inputName === 'language') {
                window.location.href = url;
            }
            else {
                openRedirectModalForDropdown(url);
            }
            // Prevent to change de selected option
            dd.value = initialValue;
        });
    });
}
exports.default = initDropdown;
